import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box } from "@mui/material";
import { useChatContext } from "src/context/ChatContext";
import { useState } from "react";

const ChatMarkDown = ({ val }) => {
  console.log("VALUE JSON OUT:", val.json_out);
  const { sendUserMessage } = useChatContext();

  return (
    <Box
      sx={{
        mr: 2,
        mt: 2,
        pl: 1,
        pb: 1,
        mb: 1,
        overflowY: "scroll",
        overflowx: "scroll",
        whiteSpace: "nowrap",
        display: "flex !important",
        flexDirection: "row",
      }}
    >
      <ReactMarkdown
        children={val.msg}
        remarkPlugins={[remarkGfm]}
        components={{
          td: ({ node, ...props }) => {
            return (
              <td
                onDoubleClick={(e) => {
                  // setLookinto(lookinto + e.target.innerText + ", ");
                  sendUserMessage("look into" + e.target.innerText);
                  console.log("Double clicked", e.target.innerText);
                }}
                {...props}
              />
            );
          },
        }}
      />
    </Box>
  );
};

export default ChatMarkDown;
