import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";

// import CreateIcon from "@mui/icons-material/Create";

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAppContext } from "src/context/AppContext";

// import { useChatContext } from "src/context/ChatContext";

// import { useChatContext } from "src/context/ChatContext";
//import QRCode from "qrcode.react";
import PdfReport1 from "./PdfReport1";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialogue() {
  //const [open, setOpen] = React.useState(false);

  const { openShowPdf, setOpenShowPdf, setMobileOpen } = useAppContext();
  //const { pdfGroups, setPdfGroups } = useChatContext();

  const handleNewWorkspace = () => {
    // newWorkspace();
    // setOpenDialog(false);
    setMobileOpen(false);
  };

  // const [embText, setEmbText] = useState("");
  // const [action, setAction] = useState("");
  // const [value, setValue] = useState("");

  // const handleClickOpen = () => {
  //   setOpenDialog(true);
  // };

  const handleClose = () => {
    setOpenShowPdf(false);
  };

  return (
    <Dialog
      fullScreen
      open={openShowPdf}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            PDF Report
          </Typography>
        </Toolbar>
      </AppBar>
      <PdfReport1 />
    </Dialog>
  );
}
