import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppContext } from "src/context/AppContext";

const Busy = () => {
  const { busy } = useAppContext();

  return (
    busy && (
      <Box sx={{ display: "flex", textAlign: "center" }}>
        <CircularProgress />
      </Box>
    )
  );
};

export default Busy;
