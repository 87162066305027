import getData from "../../AuthContext/get_data";

const clearChat = async (themeMode, setThemeMode) => {
  const new_mode = themeMode === "light" ? "dark" : "light";
  setThemeMode(new_mode);

  let taskDetails = {
    task: "clearChat",
    group: "chat_task",
  };
  //
  const data = await getData(taskDetails);

  console.log("RESPONSE FROM HAND DARK MODE: ", data);
};

export default clearChat;
