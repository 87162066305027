import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDataContext } from "src/context/DataContext";

const ScenarioSelect = () => {
  const { scenarios, scenario, setScenario } = useDataContext();

  const handleChange = (event) => {
    setScenario(event.target.value);
    console.log("ScenarioSelect:", event.target.value);
  };

  //
  return (
    <FormControl sx={{ ml: 2 }} size="small">
      {/* {JSON.stringify(scenarios)} */}
      <Select
        id="demo-select-small"
        value={scenario}
        onChange={(e) => handleChange(e)}
      >
        {scenarios.map((item) => (
          <MenuItem key={item.dim_id} value={item.dim_id}>
            {item.dim_attr_1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ScenarioSelect;
