import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
//import { formHelperTextClasses } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    archiveRows,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
          }}
        >
          <Checkbox
            style={{ color: "white" }}
            key="sdfw339"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected + archiveRows === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
          <p>{archiveRows}</p>
        </TableCell>
        {columns.map((column, i) => (
          <TableCell
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
            }}
            key={column.field + i}
            align={column.numeric ? "right" : "left"}
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              style={{ color: "white" }}
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : "asc"}
              onClick={createSortHandler(column.field)}
            >
              {column.title}
              {orderBy === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  archiveRows: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    handleSearch,
    searchable,
    title,
    checkOnly,
    setCheckOnly,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box sx={{ flex: "1 1 100%" }}>
          {/* <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
            onClick={() => setCheckOnly(!checkOnly)}
          >
            {`${numSelected} selected --  ${
              checkOnly ? "Show All" : "Show Checked only"
            } `}
          </Typography> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setCheckOnly(!checkOnly)}
          >
            {`${numSelected} selected -  ${
              checkOnly ? "Show All" : "Show Checked only"
            } `}
          </Button>
        </Box>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {searchable ? (
        <TextField
          id="input-with-icon-textfield"
          type="search"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   searchable: PropTypes.bool.isRequired,
//   checkOnly: PropTypes.bool.isRequired,
//   setCheckOnly: PropTypes.func.isRequired,
// };

const AccountPickerTable = ({
  rows,
  columns,
  dataCallback,
  doubleClickCallback,
  selectionModel,
  title,
  tableKey = "name",
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchable] = useState(true);
  const [checkOnly, setCheckOnly] = useState(false);
  const [archivesRows, setArchivesRows] = useState(0);

  useEffect(() => {
    rows.forEach((row) => {
      let bb = "";
      columns.forEach((item) => {
        bb = bb + String(row[item.field]);
      });
      row["searchMe01"] = bb.toLowerCase();
      if (selectionModel) {
        setSelected(selectionModel);
      }
    });
    setArchivesRows(rows.filter((fitem) => fitem.jv_status === 1).length);
  }, [columns, rows, selectionModel, archivesRows]);

  const handleSearch = (val) => {
    // console.log(val);
    setSearchTerm(val);
  };

  const handleRequestSort = (event, property) => {
    try {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const handleSelectAllClick = (event) => {
    console.log("HANDLE SELECT ALL CLICK");
    //try {
    let rows2 = rows.filter((fitem) => fitem.jv_status === 0);
    setCheckOnly(false);
    console.log("HXXXXXXXXXXXXXXXXX", event.target.checked);
    //
    if (event.target.checked) {
      console.log("HANDLE SELECT ALL CLICK 2", rows);
      //
      let newSelected = rows2
        .filter((fitem) => {
          if (
            searchTerm === ""
            //&& fitem["jv_status"] === 0
          ) {
            return fitem;
          } else if (
            fitem["searchMe01"].includes(searchTerm.toLowerCase())
            //&&              fitem["jv_status"] === 0
          ) {
            return fitem;
          }
        })
        .map((n) => n[tableKey]);
      //

      console.log("HANDLE SELECT ALL CLICK 3", newSelected);
      dataCallback(newSelected);
      setSelected(newSelected);
      return;
    }
    dataCallback([]);
    setSelected([]);
    // } catch (error) {
    //   console.log("ERROR:", error);
    // }
  };

  const handleClick = (event, name, row) => {
    if (row?.jv_status === 1) {
      console.log("HANDLE CLICK JV STATUS = 1");
      return;
    }

    try {
      const selectedIndex = selected.indexOf(name);

      let newSelected = [];
      //let newSelected = [...selected];
      // console.log("selected", selected);
      // console.log("name", name);
      // console.log("selectedIndex", selectedIndex);

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      dataCallback(newSelected);
      //  console.log("selected", newSelected);
      if (newSelected.length === 0) {
        setCheckOnly(false);
      }
      setSelected(newSelected);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const handleDoubleClick = (col, row) => {
    try {
      doubleClickCallback(col, row);
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const isSelected = (name) => {
    try {
      return selected.indexOf(name) !== -1;
    } catch (error) {
      console.log("ERROR:", error);
      return false;
    }
  };

  const nbrFormat = (nbr, d = 2) => {
    try {
      return nbr.toLocaleString(undefined, {
        maximumFractionDigits: d,
        minimumFractionDigits: d,
      });
    } catch (error) {
      return 0;
    }
  };

  // const viewDetails = (row) => {
  //   let msg = `Get Load Details  ${row["month"]} ${row["jv_id"]}`;
  //   console.log("viewDetails:", row);

  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <EnhancedTableToolbar
        numSelected={selected.length}
        archiveRows={archivesRows}
        handleSearch={handleSearch}
        searchable={searchable}
        title={title}
        checkOnly={checkOnly}
        setCheckOnly={setCheckOnly}
      />
      <p>{}</p>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: 3 }}>
        <TableContainer sx={{ maxHeight: 450 }}>
          <div style={{ height: "440px" }}>
            <Table stickyHeader aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                archiveRows={archivesRows}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                columns={columns}
                checkOnly={checkOnly}
                setCheckOnly={setCheckOnly}
              />
              <TableBody>
                {rows
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .filter((fitem) => {
                    //  console.log("searchMe01", searchTerm);
                    //console.log("searchMe01", fitem["name"]);
                    //
                    if (checkOnly && isSelected(fitem[tableKey])) {
                      return true; // Include the item
                    } else if (
                      searchTerm !== "" &&
                      fitem["searchMe01"]?.includes(searchTerm.toLowerCase())
                    ) {
                      return true; // Include the item
                    } else if (searchTerm === "" && !checkOnly) {
                      return true; // Include the item
                    }
                  })
                  .map((row, index) => {
                    let isItemSelected = isSelected(row[tableKey]);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row[tableKey], row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={"table-88-" + index}
                        selected={isItemSelected}
                        style={{
                          height: "15px",
                          fontSize: 12,
                          fontWeight: 300,
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            disabled={row["jv_status"] === 1}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {columns.map((col, ind) => {
                          return (
                            <TableCell
                              component="th"
                              id={col + "-" + ind + "-" + index}
                              scope="row"
                              // onDoubleClick={() => handleDoubleClick(col, row)}
                              sx={
                                col.numeric
                                  ? {
                                      textAlign: "right",
                                      pr: 2,
                                      fontSize: 12,
                                      fontWeight: 300,
                                    }
                                  : {
                                      textAlign: "left",
                                      fontSize: 12,
                                      fontWeight: 300,
                                    }
                              }
                              key={"table-99-" + ind}
                            >
                              {col.numeric ? (
                                nbrFormat(row[col.field])
                              ) : col.field === "jv_status_name" ||
                                col.field === "icon" ? (
                                <Button
                                  sx={{ width: "100%" }}
                                  onClick={() => handleDoubleClick(col, row)}
                                  size="small"
                                  variant="contained"
                                >
                                  {row[col.field]}
                                </Button>
                              ) : (
                                row[col.field]
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default AccountPickerTable;
