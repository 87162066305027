import { useAuthContext } from "./context/AuthContext";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "src/components/login";
import Main from "src/main";
import ResetPassord from "src/components/login/resetPassword";

const Routes1 = () => {
  const { session } = useAuthContext();

  return (
    <Router>
      {!session && (
        <Routes>
          <Route exact path="/reset" element={<ResetPassord />} />
          <Route exact path="/" element={<Login />} />
        </Routes>
      )}

      {session && (
        <Routes>
          <Route exact path="/reset" element={<ResetPassord />} />
          <Route exact path="/" element={<Main />} />
        </Routes>
      )}
    </Router>
  );
};

export default Routes1;
