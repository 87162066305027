import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import InfoIcon from "@mui/icons-material/Info";
import RemoveIcon from "@mui/icons-material/Remove";
//import InboxIcon from "@mui/icons-material/MoveToInbox";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

//import SlideshowIcon from "@mui/icons-material/Slideshow";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import IconButton from "@mui/material/IconButton";
//
import { useAppContext } from "src/context/AppContext";
import { useAuthContext } from "src/context/AuthContext";
import { useChatContext } from "src/context/ChatContext";
import useWindowWidth from "../../useWindowWidth";

//const drawerWidth = 240;

function SideMenu(props) {
  const { window } = props;
  const { windowWidth } = useWindowWidth();

  const { runReport, deleteReport, clearChatStream, updateLookups } =
    useChatContext();

  const handleOpenWorkspaces = () => {
    setOpenDialog(true);
    setMobileOpen(false);
    console.log("Open Workspaces");
  };

  const {
    themeMode,
    setThemeMode,
    drawerWidth,
    mobileOpen,
    setMobileOpen,
    setOpenDialog,
    setOpenData,
    logDarkMode,
    reportList,

    getUserInfo,
  } = useAppContext();

  const { logout } = useAuthContext();
  //

  const handleClearChat = () => {
    clearChatStream();
  };

  const handleDarkMode = () => {
    logDarkMode();
  };

  const handleUpdateLookups = () => {
    updateLookups();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem key="lclearmee" disablePadding>
          <ListItemButton
            onClick={() => {
              handleClearChat();
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New Chat" />
          </ListItemButton>
        </ListItem>
        {windowWidth > 600 && (
          <ListItem key="lclearms" disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenData(true);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Data" />
            </ListItemButton>
          </ListItem>
        )}

        <ListItem key="WS11" disablePadding>
          <ListItemButton
            onClick={() => {
              handleOpenWorkspaces(true);
            }}
          >
            <ListItemIcon>
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary="Workspace" />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />
      <List>
        <ListItem key="myReportsxxx" disablePadding>
          <ListItemButton onClick={() => getUserInfo()}>
            <ListItemText primary="My Reports" />
          </ListItemButton>
        </ListItem>
        {reportList.map((report, i) => (
          <ListItem key={i + "_reports"} disablePadding>
            <ListItemButton
              onClick={() => {
                runReport(report);
                setMobileOpen(false);
              }}
            >
              <ListItemText secondary={report} />
            </ListItemButton>
            <ListItemIcon
              onClick={() => {
                deleteReport(report);
                setMobileOpen(false);
              }}
            >
              <IconButton>
                <RemoveIcon fontSize="small" />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
      <Divider />

      <List>
        <ListItem key="modddeme" disablePadding>
          <ListItemButton
            onClick={() => {
              handleDarkMode();
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              {themeMode === "dark" ? <Brightness4Icon /> : <Brightness7Icon />}
            </ListItemIcon>
            <ListItemText primary={themeMode === "dark" ? "Light" : "Dark"} />
          </ListItemButton>
        </ListItem>
        <ListItem key="getInformation1" disablePadding>
          <ListItemButton
            onClick={() => {
              handleUpdateLookups();
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Lookups" />
          </ListItemButton>
        </ListItem>

        <ListItem key="lossgout1" disablePadding>
          <ListItemButton
            onClick={() => {
              setThemeMode("light");
              logout();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>

      <Box sx={{ fontSize: "12px", mt: 15, ml: 2 }}></Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  // {drawer} is the menu.  It has two different renders one for mobile width and one for desktop width.
  return (
    <div>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

export default SideMenu;
