// import { useLayoutEffect, useState, useRef } from "react";

// function getWindowDimensions() {
//   const { innerWidth: width } = window;
//   return width; // Return only the width
// }

// const useWindowWidth = () => {
//   const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
//   const resizeObserverRef = useRef(null); // Store the ResizeObserver instance

//   useLayoutEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(getWindowDimensions());
//     };

//     // Create a ResizeObserver instance on the window
//     resizeObserverRef.current = new ResizeObserver(handleResize);
//     resizeObserverRef.current.observe(window);

//     // Cleanup function to remove the observer on unmount
//     return () => {
//       if (resizeObserverRef.current) {
//         resizeObserverRef.current.disconnect(); // Disconnect the observer
//       }
//     };
//   }, []);

//   return { windowWidth };
// };

// export default useWindowWidth;

import { useLayoutEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width; // Return only the width
}

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return { windowWidth };
};

export default useWindowWidth;
