import getData from "../AuthContext/get_data";

//setChatStream, currentWorkspace, message, options
const handleSendMessage = async ({ currentWorkspace, message, options }) => {
  console.log("HANDLE SEND MESSAGE : 1");

  // GET DATA
  const retData = await getData({
    task: "sendUserMessage",
    group: "message",
    msg: message,
    workSpaceId: currentWorkspace,
    options: options,
  });

  console.log("HANDLE SEND MESSAGE : 2", retData);

  if (retData.error) {
    console.error(
      "HANDLE SEND MESSAGE : 3 Error sending message:",
      retData.message
    );
    return retData;
  }

  if (!retData.data) {
    console.error(
      "HANDLE SEND MESSAGE : 4 Error sending message 12: No data returned"
    );
    return retData;
  }

  console.log("FETCH RETURN: ", retData);

  console.log("DATA: ", retData.data);

  if (!retData.data.returnChat) {
    console.error(
      "HANDLE SEND MESSAGE : 5 Error sending message 13: No chat data returned"
    );
    return retData;
  }

  console.log("HANDLE SEND MESSAGE : 6 SEND MESSAGE COMPLETE");
  return retData.data;
};

export default handleSendMessage;
