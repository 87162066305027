import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { useAppContext } from "src/context/AppContext";

const padding_1 = 4;
const color2 = "#fcffcd";
const color1 = "skyblue";

const styles = StyleSheet.create({
  row: {
    1: {
      flexDirection: "row",
    },
    2: {
      flexDirection: "row",
    },
    3: {
      flexDirection: "row",
      backgroundColor: color1,
    },
    4: {
      flexDirection: "row",
      backgroundColor: color1,
    },
    99: {
      flexDirection: "row",
      backgroundColor: color1,
      fontColor: "white",
    },
  },
  row_cell: {
    1: {
      padding: padding_1,
      border: "1px solid #000",
      width: "35%", // Adjust the width for the "Account" column
      backgroundColor: color2,
      alignItems: "center",
    },
    2: {
      padding: 4,
      paddingLeft: 5,
      border: "1px solid #000",
      width: "35%", // Adjust the width for the "Account" column
      backgroundColor: color1,
      alignItems: "center",
    },
    3: {
      padding: 4,
      paddingLeft: 10,
      border: "1px solid #000",
      width: "35%", // Adjust the width for the "Account" column
      backgroundColor: color1,
      alignItems: "center",
    },
    4: {
      paddingTop: 4,
      paddingLeft: 20,
      border: "1px solid #000",
      width: "35%", // Adjust the width for the "Account" column
      backgroundColor: color1,
      alignItems: "center",
    },
    99: {
      padding: padding_1,
      border: "1px solid #000",
      width: "35%", // Adjust the width for the "Account" column
      alignItems: "center",
      textAlign: "middle",
      fontSize: 9,
    },
  },
  row_cell_num: {
    1: {
      padding: padding_1,
      border: "1px solid #000",
      width: "15%", // Adjust the width for numeric columns
      textAlign: "right",
      alignItems: "center", // Adjust the width for numeric columns
    },
    2: {
      padding: padding_1,
      border: "1px solid #000",
      width: "15%", // Adjust the width for numeric columns
      textAlign: "right",
      alignItems: "center", // Adjust the width for numeric columns
    },
    3: {
      padding: padding_1,
      border: "1px solid #000",
      width: "15%", // Adjust the width for numeric columns
      textAlign: "right",
      alignItems: "center", // Adjust the width for numeric columns
    },
    4: {
      padding: padding_1,
      border: "1px solid #000",
      width: "15%", // Adjust the width for numeric columns
      textAlign: "right",
      alignItems: "center", // Adjust the width for numeric columns
    },
    99: {
      padding: padding_1,
      border: "1px solid #000",
      width: "15%", // Adjust the width for the "Account" column
      alignItems: "center",
      textAlign: "middle",
      fontSize: 9,
    },
  },
});

// const formatNumber = (input) => {
//   const num = Number(input);
//   if (!isNaN(num)) {
//     if (num === 0) {
//       return "";
//     }
//     return num.toLocaleString("en-US", {
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0,
//     });
//   } else {
//     return input;
//   }
// };

const PdfMakeHeader = ({ pdf_obj }) => {
  const { workspaces } = useAppContext();
  //
  console.log("WORKSPACES:", workspaces);
  //
  return (
    <View>
      <View>
        <Text>
          {/* {workspaces.filter((f) => f.ws_id === currentWorkspace)[0].ws_desc} */}
        </Text>
      </View>

      {pdf_obj.report_name > "" && (
        <View>
          <Text>Report Name</Text>
          <Text>{pdf_obj.report_name}</Text>
        </View>
      )}
      <View>
        <Text>Report Filters</Text>
        <Text>{pdf_obj.dim1_text}</Text>
        <Text>{pdf_obj.dim2_text}</Text>
        <Text>{pdf_obj.dim3_text}</Text>
      </View>
      <View>
        <Text>{`${pdf_obj.periodBegTxt} to ${pdf_obj.periodEndTxt}`}</Text>
      </View>
    </View>
  );
};

export default PdfMakeHeader;
