import { createContext, useContext, useState } from "react";
import { AppContext } from "../AppContext"; // Import the renamed AuthContext

import handleSendMessage from "./handle_send_message";
//import handleRunReport from "./handle_run_report";

export const ChatContext = createContext({});
export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const { currentWorkspace, busy, setBusy } = useContext(AppContext);
  const [chatStream, setChatStream] = useState([]);
  const [pdfGroups, setPdfGroups] = useState([]);
  //const [message, setMessage] = useState("");

  const updateChatStream = async (newMessages) => {
    if (!newMessages) {
      return;
    }
    if (newMessages.length === 0) {
      return;
    }
    setChatStream((prevChatStream) => [...prevChatStream, ...newMessages]);
  };

  const addUserMsg = async (message) => {
    console.log("XXXXX ADD USER MSG: ", message);
    await updateChatStream([
      {
        msg: message,
        msgType: "user",
        options: { format: "txt" },
      },
    ]);
  };

  const sendUserMessage = async (message, options = {}) => {
    console.log("SEND USER MSG: ", message);

    setBusy(true);
    await addUserMsg(message);

    const msgBack = await handleSendMessage({
      message: message,
      options: options,
      currentWorkspace: currentWorkspace,
    });

    console.log("AAAAAA SEND USER MSG BACK: ", msgBack.data);

    await updateChatStream(msgBack.data);
    setBusy(false);
  };

  const runReport = async (reportName) => {
    console.log("RUN REPORT DONE");
    sendUserMessage(`Running report: ${reportName}`, {
      action_code: "run_report",
      report_name: reportName,
    });
    return "done";
  };

  const deleteReport = async (reportName) => {
    console.log("Delete Report");
    sendUserMessage(`Delete report: ${reportName}`, {
      action_code: "confirm_delete_report",
      report_name: reportName,
    });
    return "done";
  };

  const clearChatStream = () => {
    console.log("CLEAR");
    setChatStream([]);
    sendUserMessage("New Chat", { action_code: "clear_chat" });
    return "done";
  };

  const updateLookups = () => {
    console.log("LOOKUPS");
    setChatStream([]);
    sendUserMessage("Update Lookups", { action_code: "update_lookups" });
    return "done";
  };

  const value = {
    clearChatStream,
    chatStream,
    sendUserMessage,
    pdfGroups,
    setPdfGroups,
    setChatStream,
    busy,
    setBusy,
    runReport,
    deleteReport,
    updateLookups,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
