import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDataContext } from "src/context/DataContext";

const PeriodSelect = () => {
  const { period, setPeriod } = useDataContext();

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <FormControl sx={{ ml: 2 }} size="small">
      <Select id="demo-select-small" value={period} onChange={handleChange}>
        <MenuItem value={"this_year"}>This Year</MenuItem>
        <MenuItem value={"this_quarter"}>This Quarter</MenuItem>
        <MenuItem value={"next_year"}>Next Year</MenuItem>
        <MenuItem value={"next_quarter"}>Next Quarter</MenuItem>
        <MenuItem value={"jan"}>January</MenuItem>
        <MenuItem value={"feb"}>February</MenuItem>
        <MenuItem value={"mar"}>March</MenuItem>
        <MenuItem value={"apr"}>April</MenuItem>
        <MenuItem value={"may"}>May</MenuItem>
        <MenuItem value={"jun"}>June</MenuItem>
        <MenuItem value={"jul"}>July</MenuItem>
        <MenuItem value={"aug"}>August</MenuItem>
        <MenuItem value={"sep"}>September</MenuItem>
        <MenuItem value={"oct"}>October</MenuItem>
        <MenuItem value={"nov"}>November</MenuItem>
        <MenuItem value={"dec"}>December</MenuItem>
        <MenuItem value={"2030"}>2030</MenuItem>
        <MenuItem value={"2029"}>2029</MenuItem>
        <MenuItem value={"2028"}>2028</MenuItem>
        <MenuItem value={"2027"}>2027</MenuItem>
        <MenuItem value={"2026"}>2026</MenuItem>
        <MenuItem value={"2025"}>2025</MenuItem>
        <MenuItem value={"2024"}>2024</MenuItem>
        <MenuItem value={"2023"}>2023</MenuItem>
        <MenuItem value={"2022"}>2022</MenuItem>
        <MenuItem value={"2021"}>2021</MenuItem>
        <MenuItem value={"2020"}>2020</MenuItem>
        <MenuItem value={"2019"}>2019</MenuItem>
        <MenuItem value={"2018"}>2018</MenuItem>
        <MenuItem value={"2017"}>2017</MenuItem>
        <MenuItem value={"2016"}>2016</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PeriodSelect;
