import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box } from "@mui/material";
import { useState } from "react";
//import remarkRehype from "remark-rehype";
// import "./markdown.css";
import useWindowWidth from "../../useWindowWidth";

const ChatMarkDown = ({ val }) => {
  const { windowWidth } = useWindowWidth();
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (key) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  let bb = 1;

  const plus_bb = () => {
    bb = bb + 1;
    console.log("BB", bb);
  };

  return (
    <Box
      sx={{
        mr: 2,
        pl: 1,
        overflowY: "scroll",
        overflowx: "scroll",
        whiteSpace: "wrap",
        maxWidth: windowWidth - 10,
      }}
    >
      <ReactMarkdown
        children={val.msg}
        remarkPlugins={[remarkGfm]}
        components={{
          input: ({ node, ...props }) => {
            plus_bb();
            // Use node position as a unique key
            // const key = node.position.start.offset;
            // // Remove 'disabled' attribute
            const { disabled, ...rest } = props;
            // // Determine initial checked state
            // const isChecked =
            //   checkedItems[key] !== undefined
            //     ? checkedItems[key]
            //     : props.checked;
            return (
              <input
                type="checkbox"
                checked={true}
                id={"PPP-" + bb}
                onChange={(val) => {
                  console.log("Checkbox clicked", val);
                }}
                {...rest}
              />
            );
          },
        }}
      />
    </Box>
  );
};

export default ChatMarkDown;
