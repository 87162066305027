import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useAuthContext } from "src/context/AuthContext";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import supabase from "../../context/AuthContext/makeSupabase";

export default function Index() {
  const { session, logout } = useAuthContext();

  if (session === 0) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ m: 4, border: "1px solid black", p: 3 }}>
          <Auth
            supabaseClient={supabase}
            providers={["google", "azure"]}
            appearance={{ theme: ThemeSupa }}
          />
        </Box>
      </div>
    );
  } else if (session === 1) {
    return (
      <div>
        <Button onClick={() => logout()}>log out</Button>
        <div>Logged in!</div>
        <div>{JSON.stringify(session)}</div>
      </div>
    );
  }
}
