import getData from "../../AuthContext/get_data";

const logDarkMode = async (themeMode, setThemeMode) => {
  const new_mode = themeMode === "light" ? "dark" : "light";
  setThemeMode(new_mode);

  let taskDetails = {
    task: "darkMode",
    group: "task",
    darkMode: new_mode,
  };
  //
  const data = await getData(taskDetails);

  console.log("RESPONSE FROM HAND DARK MODE: ", data);
};

export default logDarkMode;
