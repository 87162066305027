import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
//import { useAuthContext } from "src/context/AuthContext";
import Box from "@mui/material/Box";

import supabase from "../../context/AuthContext/makeSupabase";

const mobile_url = process.env.REACT_APP_MOBILE_URL;

export default function Index() {
  //const { supabase } = useAuthContext();

  return (
    <Box sx={{ m: 4 }}>
      <Auth
        supabaseClient={supabase}
        providers={[]}
        view="update_password"
        redirectTo={mobile_url}
        appearance={{ theme: ThemeSupa }}
      />
    </Box>
  );
}
