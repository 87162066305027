import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { StyledEngineProvider } from "@mui/material/styles";
import { AppProvider } from "./context/AppContext";

import App from "./App";
import { AuthProvider } from "./context/AuthContext";

ReactDOM.createRoot(document.querySelector("#root")).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </AuthProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
