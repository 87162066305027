import { createContext, useContext, useState, useEffect } from "react";
import supabase from "../AuthContext/makeSupabase";

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(2); // 0: not logged in, 1: logged in, 2: unknown

  // GET USER SESSION
  useEffect(() => {
    console.log("1 USE EFFECT GET USER SESSION");
    //
    const fetchData = async () => {
      const ses = await supabase.auth.getSession();
      console.log("2 TESTME SES", ses);
      if (ses.error !== null) {
        console.log("2 TESTME ERROR 9");
        setSession(0);
      } else if (ses.data === null) {
        console.log("3 TESTME NULL SET SESSION 0");
        setSession(0);
      } else if (ses.data.session === null) {
        console.log("4 TESTME SESSION NULL SET SESSION 0");
        setSession(0);
      } else if (ses.data.session != null) {
        console.log("4 TESTME NOT NULL SET SESSION 1");
        setSession(1);
      }
    };
    if (session !== 1) {
      fetchData();
      console.log("5 TESTME SESSION NOT 1");
    }
  }, []);

  const test44 = "TESTME";

  useEffect(() => {
    console.log("4 SESSION STATUS", session); // This will log when 'session' changes
  }, [session]); // Add session as a dependency

  const logout = async () => {
    await supabase.auth.signOut();
    setSession(0);
  };

  const value = {
    session,
    test44,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
