import { createContext, useContext, useState, useEffect } from "react";
import handleDarkMode from "./handle_darkmode";
import handleUserInfo from "./handle_userInfo";
import handleUpdateLookups from "./handle_update_lookups";
import handleClearChat from "./handle_clear_chat";

//
import { AuthContext } from "../AuthContext"; // Import the renamed AuthContext

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const { session } = useContext(AuthContext); // Access session from AuthContext
  //
  const [reportDrawerShow, setReportDrawerShow] = useState(false);
  const [menuDrawerShow, setMmenuDrawerShow] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [openShowPdf, setOpenShowPdf] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [themeMode, setThemeMode] = useState("light");
  const [drawerWidth, setDrawerWidth] = useState(240);
  //
  const [busy, setBusy] = useState(true);
  const [workspaces, setWorkspaces] = useState([{ ws_id: 0, ws_desc: "Home" }]);
  const [currentWorkspace, setCurrentWorkspace] = useState(0);
  const [reportList, setReportList] = useState(["NO REPORTS"]);
  //

  const getUserInfo = async (ws_id) => {
    setBusy(true);
    await handleUserInfo(
      ws_id,
      setWorkspaces,
      setReportList,
      setCurrentWorkspace,
      setThemeMode
    );
    setBusy(false);
    return null;
  };

  const logDarkMode = async () => {
    await handleDarkMode(themeMode, setThemeMode, currentWorkspace, setBusy);
    return null;
  };

  const updateLookups = async () => {
    console.log("UPDATE LOOKUPS");
    setBusy(true);
    await handleUpdateLookups({ currentWorkspace: currentWorkspace });
    setBusy(false);
    console.log("UPDATE LOOKUPS DONE");
    return null;
  };

  const clearChat = async () => {
    await handleClearChat(themeMode, setThemeMode, currentWorkspace, setBusy);
    return null;
  };

  useEffect(() => {
    if (session === 1) {
      getUserInfo(currentWorkspace);
    }
  }, [session, currentWorkspace]);

  const values = {
    updateLookups,
    busy,
    clearChat,
    currentWorkspace,
    drawerWidth,
    getUserInfo,
    logDarkMode,
    menuDrawerShow,
    mobileOpen,
    openData,
    openDialog,
    openShowPdf,
    reportDrawerShow,
    reportList,
    setBusy,
    setCurrentWorkspace,
    setDrawerWidth,
    setMmenuDrawerShow,
    setMobileOpen,
    setOpenData,
    setOpenDialog,
    setOpenShowPdf,
    setReportDrawerShow,
    setReportList,
    setShowInfo,
    setThemeMode,
    setWorkspaces,
    showInfo,
    themeMode,
    workspaces,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
