import { createContext, useContext, useState, useEffect } from "react";
//import { useAuthContext } from "../AuthContext";
import { AppContext } from "../AppContext";
import getData from "../AuthContext/get_data";

export const DataContext = createContext({});
export const useDataContext = () => useContext(DataContext);

//
export const DataProvider = ({ children }) => {
  const { currentWorkspace } = useContext(AppContext);
  const [period, setPeriod] = useState("this_year");
  const [scenario, setScenario] = useState(1);
  const [jv_id_periods, setJv_id_periods] = useState([]);
  const [scenarios, setScenarios] = useState([
    { dim_id: 1, dim_attr_1: "Actual" },
  ]);

  const [columns, setColumns] = useState([
    { title: "Entity", field: "dim_attr_1" },
    { title: "Description", field: "jv_name" },
    { title: "View", field: "icon" },
    { title: "Status", field: "jv_status_name" },
    { title: "Currency", field: "currency_code" },
    { title: "Id", field: "jv_id" },
    { title: "Month", field: "month" },
    { title: "User", field: "user_name" },
    { title: "Date", field: "day" },
  ]);

  const [rows, setRows] = useState([
    {
      entity_desc: "xxx",
      jv_desc: "xxx",
      currency_code: "USD",
      icon: "xxx",
      jv_id: "xxx",
      jv_status: 0,
      month: "2301",
      user_name: "xxx",
      day: "xxx",
    },
  ]);

  const showTime = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diff = now - date;
    const diffInMinutes = Math.round(diff / (1000 * 60)); // Calculate the difference in minutes

    if (diffInMinutes < 60) {
      if (diffInMinutes > 1) {
        return `${diffInMinutes} minutes ago`;
      } else {
        return "1 minute ago";
      }
    } else if (diffInMinutes < 1440) {
      // Less than 24 hours
      const diffInHours = Math.round(diffInMinutes / 60); // Calculate the difference in hours
      return `${diffInHours} hours ago`;
    } else if (diffInMinutes < 4320) {
      // Less than 3 days (3 days * 24 hours * 60 minutes)
      const diffInDays = Math.round(diffInMinutes / 1440); // Calculate the difference in days
      return `${diffInDays} days ago`;
    } else {
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    }
  };

  useEffect(() => {
    let filter = `scenario_flag_${scenario}`;
    //console.log("FILTER:", filter);

    setJv_id_periods(
      rows
        .filter((fitem) => fitem[filter] === 1)
        .map((row) => row["jv_id_period"])
    );
  }, [scenario, rows]);

  const getLedgerParents = async (action) => {
    console.log("OPTION CONFIRM:", action);
    const { jv_id_period, jv_status } = action;

    const retData = await getData({
      task: "getLedgerParents",
      group: "task",
      scenario_id: scenario,
      workSpaceId: currentWorkspace,
      period: period,
      jv_id_period: jv_id_period,
      jv_status: jv_status,
    });

    // console.log("HANDLE SEND MESSAGE : 2", retData);

    if (retData.error) {
      console.error(
        "HANDLE SEND MESSAGE : 3 Error sending message:",
        retData.message
      );
      return retData;
    }

    if (!retData.data) {
      console.error(
        "HANDLE SEND MESSAGE : 4 Error sending message 12: No data returned"
      );
      return retData;
    }

    //  console.log("RESPONSE:", retData);

    const jvdata = retData.data;

    jvdata.map((row) => {
      row["day"] = showTime(row["last_update"]);
      row["name"] = row["jv_id_period"];
      row["jv_status_name"] = row["jv_status"] === 1 ? "Archived" : "Active";
      row["status"] = row["jv_status"];
    });

    //console.log("NDATAxxxxxxxxxxx:", ndata);

    let filter = `scenario_flag_${scenario}`;
    // console.log("FILTER:", filter);

    setJv_id_periods(
      jvdata
        .filter((fitem) => fitem[filter] === 1)
        .map((row) => row["jv_id_period"])
    );

    //setRows(ndata);

    jvdata.map((row) => (row["icon"] = "View"));

    setRows(jvdata);
    // } catch (error) {
    //   console.log("ERROR GET PARENT LEDGERS:", error);
    // }
  };

  const getScenarios = async () => {
    console.log("GET SCENARIOS");
    try {
      const response = await getData({
        task: "getScenarios",
        group: "task",
        workSpaceId: currentWorkspace,
      });

      console.log("RESPONSE:", response);
      let scenarioData = response.data;
      if (scenarioData.length > 0) {
        setScenarios(scenarioData);
      }
    } catch (error) {
      console.log("ERROR GET PARENT LEDGERS:", error);
    }
  };

  const updateCube = async (action) => {
    console.log("OPTION CONFIRM:", action);

    try {
      const response = await getData({
        task: "updateCube",
        group: "task",
        scenario_id: scenario,
        period: period,
        jv_id_periods: jv_id_periods,
      });

      console.log("RESPONSE:", response);

      const jvdata = response.data;

      jvdata.map((row) => {
        row["day"] = showTime(row["last_update"]);
        row["name"] = row["jv_id_period"];
      });

      //console.log("NDATAxxxxxxxxxxx:", ndata);

      let filter = `scenario_flag_${scenario}`;
      console.log("FILTERx:", filter);

      setJv_id_periods(
        jvdata
          .filter((fitem) => fitem[filter] === 1)
          .map((row) => row["jv_id_period"])
      );

      //setRows(ndata);

      setRows(jvdata);
    } catch (error) {
      console.log("ERROR GET PARENT LEDGERS:", error);
    }
  };

  const value = {
    getLedgerParents,
    getScenarios,
    columns,
    setColumns,
    rows,
    updateCube,
    period,
    setPeriod,
    scenario,
    setScenario,
    jv_id_periods,
    setJv_id_periods,
    scenarios,
    setScenarios,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
