import { Text, View, StyleSheet } from "@react-pdf/renderer";

const padding_1 = 4;
//const color2 = "#fcffcd";

//const color1 = "#8cbed6";
// const color1 = "#3C7EC9";
// const color2 = "skyblue";
// const color3 = "white";

//Font.register({ family: 'Roboto', src: source });

const styles = StyleSheet.create({
  main: {
    flexDirection: "column",
    fontSize: 6,
  },
  row: {
    1: {
      flexDirection: "row",
    },
  },
  row_one: {
    padding: padding_1,
    border: "1px solid #000",
    fontWeight: "bold",
    width: "10%",
    textAlign: "left",
  },
  row_cell: {
    1: {
      padding: padding_1,
      border: "1px solid #000",
      fontWeight: "bold",
      width: "10%",
      textAlign: "left",
    },
  },
  row_cell_col: {
    padding: padding_1,
    border: "1px solid #000",
    fontWeight: "bold",
    width: "30%",
    textAlign: "left",
  },

  row_cell_num: {
    1: {
      padding: padding_1,
      border: "1px solid #000",
      width: "10%",
    },
  },
});

const formatNumber = (input) => {
  const num = Number(input);
  if (!isNaN(num)) {
    if (num === 0) {
      return "";
    }
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    return input;
  }
};

const PdfMakeTable = ({ data, pdfHeader }) => {
  console.log("PdfMakeTable DETAILS");
  console.log("PdfMakeTable: data", data);
  console.log("PdfMakeTable: pdfHeader", pdfHeader);
  return (
    <View style={styles.main}>
      {data.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.row[1]}>
          {pdfHeader.map((label, labelIndex) =>
            rowIndex === 0 ? (
              <View
                key={labelIndex}
                style={
                  labelIndex === 9
                    ? styles.row_cell_col
                    : styles.row_cell_num[1]
                }
              >
                <Text>{String(row[label])}</Text>
              </View>
            ) : (
              <Text
                key={labelIndex}
                style={
                  labelIndex === 9
                    ? styles.row_cell_col
                    : styles.row_cell_num[1]
                }
              >
                {formatNumber(row[label])}{" "}
              </Text>
            )
          )}
        </View>
      ))}
    </View>
  );
};

export default PdfMakeTable;
