import { Box, IconButton } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
//import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import JSONToCSVConvertor from "src/components/saveAsCsv/JSONToCSVConvertor";
import { useAppContext } from "src/context/AppContext";
import { useChatContext } from "src/context/ChatContext";
//import saveToFile from "../../saveAsCsv/saveToFile";

//import remarkRehype from "remark-rehype";
// import "./markdown.css";

const ChatMsgEnd = ({ val }) => {
  const { setOpenShowPdf } = useAppContext();
  // const { setPdfGroups } = useChatContext();
  const { setPdfGroups, chatStream } = useChatContext();
  //
  //let group_id = val?.group_id;
  //console.log("GROUP ID FROM CHAT MSG END", group_id);

  const getJson = async (group_id) => {
    const item_5 = chatStream.filter(
      (val) => group_id.includes(val.group_id) && val.item === "5"
    );

    console.log("ITEM 5", item_5);

    if (item_5.length > 0 && item_5[0].hasOwnProperty("json_out")) {
      console.log("DOWNLOAD DATA DATA FROM 5", item_5[0].json_out);
      return item_5[0].json_out;
    } else {
      console.log("NO PDF DATA");
      return [{}];
    }
  };

  const handlePdf = (val) => {
    console.log("PDF ITEM", val);
    let group_id = val?.group_id || "NOGRP";
    setPdfGroups(group_id);
    setOpenShowPdf(true);
  };

  const handleDownLoad = async (val) => {
    console.log("DOWNLOAD ITEM", val);

    let group_id = val?.group_id || "NOGRP";
    const dl_data = await getJson(group_id);
    setPdfGroups(group_id);
    JSONToCSVConvertor(dl_data, "data.csv", true);
  };

  //
  return (
    <>
      <Box
        sx={{
          mr: 2,
          pl: 1,
          pb: 0,
          mb: 1,
          overflowY: "scroll",
          overflowx: "scroll",
          whiteSpace: "nowrap",
          display: "flex !important",
          flexDirection: "row",
        }}
      >
        <IconButton size="small" onClick={() => handleDownLoad(val)}>
          <SaveAltIcon size="small" fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => handlePdf(val)}>
          <PictureAsPdfIcon size="small" fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={() => console.log("THUMBS DOWN")}>
          <ThumbDownOffAltIcon size="small" fontSize="small" />
        </IconButton>
        {/* <div width="300">{JSON.stringify(val?.json_out)}</div> */}
      </Box>
    </>
  );
};

export default ChatMsgEnd;
