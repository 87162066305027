import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_PROJ_URL,
  process.env.REACT_APP_PROJ_KEY,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
  }
);

export default supabase;
