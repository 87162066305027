import Typography from "@mui/material/Typography";
import useWindowWidth from "../../useWindowWidth";
import { Box } from "@mui/material";

function isValidString(str) {
  return typeof str === "string" && str.trim() !== "";
}

const ChatTextItem = ({ val }) => {
  const { windowWidth } = useWindowWidth();
  try {
    if (isValidString(val.msg)) {
      // Use yourStringVariable in your React text object
      return (
        <Box
          sx={{
            pt: 3,
            mt: 1,
            pl: 1,
            pb: 3,

            maxWidth: { windowWidth } - 10,
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "wrap",
          }}
        >
          <Typography>{val.msg}</Typography>{" "}
        </Box>
      );
    } else {
      return <Typography>{`Missing Item ${JSON.stringify(val)}`}</Typography>;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default ChatTextItem;
