import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";

import CreateIcon from "@mui/icons-material/Create";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, List, ListItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAppContext } from "src/context/AppContext";

import { useChatContext } from "src/context/ChatContext";
//import QRCode from "qrcode.react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialogue() {
  //const [open, setOpen] = React.useState(false);

  const {
    openDialog,
    setOpenDialog,
    setMobileOpen,
    workspaces,
    currentWorkspace,
    getUserInfo,
  } = useAppContext();

  const { renameWorkspace } = useChatContext();

  const handleNewWorkspace = () => {
    //newWorkspace();
    setOpenDialog(false);
    setMobileOpen(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Workspaces
          </Typography>
          <Box sx={{ p: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                handleNewWorkspace();
              }}
            >
              New Workspace
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" gutterBottom component="div">
              <List>
                {workspaces.map((ws) => (
                  <ListItem key={ws.ws_id} disablePadding>
                    <Button
                      sx={{ m: 3, width: "75%" }}
                      key={ws.ws_id}
                      variant="contained"
                      color={
                        ws.ws_id === currentWorkspace ? "secondary" : "primary"
                      }
                      onClick={() => {
                        getUserInfo(ws.ws_id);
                        handleClose();
                      }}
                    >
                      {ws.ws_desc}
                    </Button>
                    <IconButton
                      sx={{ m: 3 }}
                      disabled={ws.ws_id !== currentWorkspace}
                      onClick={() => {
                        renameWorkspace(ws.ws_id);
                        handleClose();
                      }}
                    >
                      <CreateIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
