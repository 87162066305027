import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useState } from "react";
import useWindowWidth from "../../useWindowWidth";

function isText(value) {
  return typeof value === "string";
}

const Check_list = ({ val }) => {
  const [checkboxes, setCheckboxes] = useState(val.checkboxes);
  const { windowWidth } = useWindowWidth();

  const handleCheckboxChange = (index) => (e) => {
    const updatedCheckboxes = checkboxes.map((check, i) =>
      i === index ? { ...check, check: e.target.checked } : check
    );
    setCheckboxes(updatedCheckboxes);
  };

  const go_to_journal = (i) => () => {
    let bb = JSON.stringify(checkboxes[i]);
    console.log("go to journal:", bb);
  };

  return (
    <Box
      sx={{
        mr: 2,
        pl: 1,
        maxWidth: { windowWidth } - 10,
        flexDirection: "row",
        flexWrap: "wrap",
        overflow: "wrap",
      }}
    >
      {isText(val.msg) && (
        <ReactMarkdown children={val.msg} remarkPlugins={[remarkGfm]} />
      )}
      <ul>
        {checkboxes.map((check, i) => (
          <Box key={i + "pp"}>
            <input
              type="checkbox"
              id={"bbb" + check.id + "ddd"}
              checked={check.check}
              onChange={handleCheckboxChange(i)}
              style={{ marginRight: "15px" }}
            />
            <label onClick={go_to_journal(i)}>{check.label}</label>
          </Box>
        ))}
      </ul>
    </Box>
  );
};

export default Check_list;
