import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useChatContext } from "src/context/ChatContext";
//import PdfMakeTable from "./pdfMakeTable";
import PdfMakeTable from "./pdfMakeTable_detail";

import PdfMakeHeader from "./pdfMakeHeader";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

// const account = [
//   {
//     Id: 1,
//     Code: "400",
//     Name: "Store Sales",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 2,
//     Code: "410",
//     Name: "Catering Sales",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 3,
//     Code: "",
//     Name: "Total Revenue",
//     Type: "Subtotal",
//     Formula: "None",
//   },
//   {
//     Id: 4,
//     Code: "601",
//     Name: "Associates",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 5,
//     Code: "602",
//     Name: "Senior Associates",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 6,
//     Code: "603",
//     Name: "Managers",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 7,
//     Code: "",
//     Name: "Total Labor",
//     Type: "Subtotal",
//     Formula: "None",
//   },
//   {
//     Id: 8,
//     Code: "610",
//     Name: "Usage",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 9,
//     Code: "610",
//     Name: "Materials",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 10,
//     Code: "611",
//     Name: "Spoilage",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 11,
//     Code: "612",
//     Name: "Other Labor",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 12,
//     Code: "613",
//     Name: "Cleaning Service",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 13,
//     Code: "614",
//     Name: "Equipment Service",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 14,
//     Code: "",
//     Name: "Total Other Costs",
//     Type: "Subtotal",
//     Formula: "None",
//   },
//   {
//     Id: 15,
//     Code: "620",
//     Name: "Insurance",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 16,
//     Code: "621",
//     Name: "Utilities",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 17,
//     Code: "623",
//     Name: "Leasehold",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 18,
//     Code: "624",
//     Name: "Allocated IT",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 19,
//     Code: "",
//     Name: "Total Fixed Expenses",
//     Type: "Subtotal",
//     Formula: "None",
//   },
//   {
//     Id: 20,
//     Code: "",
//     Name: "Total Costs",
//     Type: "Formula",
//     Formula: "[Total Labor] + [Total Other Costs] + [Total Fixed Expenses]",
//   },
//   {
//     Id: 21,
//     Code: "",
//     Name: "Store Profitability",
//     Type: "Formula",
//     Formula: "[Total Revenue] - [Total Costs]",
//   },
//   {
//     Id: 22,
//     Code: "",
//     Name: "Labor Percent",
//     Type: "Formula",
//     Formula: "([Total Labor] / [Total Revenue])*100",
//   },
//   {
//     Id: 23,
//     Code: "999",
//     Name: "Customer Visits",
//     Type: "Input",
//     Formula: "None",
//   },
//   {
//     Id: 24,
//     Code: "",
//     Name: "Revenue per Customer",
//     Type: "Formula",
//     Formula: "([Customer Visits] / [Total Revenue])",
//   },
// ];

// const data = [
//   {
//     Account: "Scenario",
//     act_type: 99,
//     act_id: 99,
//     aaa00: "Actuals",
//     aaa01: "Total Actuals ssss",
//   },
//   {
//     Account: "Charisse Income",
//     act_type: 1,
//     act_id: 1,
//     aaa00: -209978.57,
//     aaa01: -209978.57,
//   },
//   {
//     Account: "Investments",
//     act_type: 1,
//     act_id: 2,
//     aaa00: null,
//     aaa01: null,
//   },
//   {
//     Account: "Tad Income",
//     act_type: 1,
//     act_id: 3,
//     aaa00: -314626.09,
//     aaa01: -314626.09,
//   },
//   {
//     Account: "Other Income",
//     act_type: 1,
//     act_id: 4,
//     aaa00: -9802.85,
//     aaa01: -9802.85,
//   },
//   {
//     Account: "Income Tax",
//     act_type: 1,
//     act_id: 5,
//     aaa00: -58656.54,
//     aaa01: -58656.54,
//   },
//   {
//     Account: "Total Income",
//     act_type: 3,
//     act_id: 6,
//     aaa00: -593064.05,
//     aaa01: -593064.05,
//   },
// ];

const PdfReport1 = () => {
  // Create styles
  const { pdfGroups, chatStream } = useChatContext();
  const [desc, setDesc] = useState("NO DESCRIPTION");
  const [pdfData, setPdfData] = useState([{}]);
  const [pdfHeader, setPdfHeader] = useState(["account"]);
  const [orientation, setOrientation] = useState("portrait");
  const [pdf_Obj, setPdf_Obj] = useState({});

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      flexDirection: "column",
      fontSize: 10,
      padding: 15,
    },
  });

  // let chatGroup = chatStream.filter((val) => pdfGroups.includes(val.group_id));
  // setDesc(chatGroup[0]["msg"]);
  // console.log("CHAT GROUP", chatGroup[0]["msg"]);

  useEffect(() => {
    // Filter headers based on conditions and set the first applicable 'msg' if available
    const headers = chatStream.filter(
      (val) => pdfGroups.includes(val.group_id) && val.item === "15"
    );
    if (headers.length > 0 && headers[0].hasOwnProperty("msg")) {
      setDesc(headers[0].msg);
    } else {
      // Handle the case where no valid header is found or 'msg' is missing
      // For example, setDesc to a default value or leave as is
    }
    const item_5 = chatStream.filter(
      (val) => pdfGroups.includes(val.group_id) && val.item === "5"
    );

    console.log("ITEM 5", item_5);

    if (item_5.length > 0 && item_5[0].hasOwnProperty("pdf_data")) {
      setPdfData(item_5[0].pdf_data);

      console.log("PDF DATA FROM 5", item_5[0].pdf_data);
    } else {
      console.log("NO PDF 5 DATA");
      // Handle the case where no valid header is found or 'msg' is missing
      // For example, setDesc to a default value or leave as is
    }

    if (item_5.length > 0 && item_5[0].hasOwnProperty("pdf_hdr")) {
      setPdfHeader(item_5[0].pdf_hdr);
      if (item_5[0].pdf_hdr.length > 12) {
        setOrientation("landscape");
      }
    }

    const item_99 = chatStream.filter(
      (val) => pdfGroups.includes(val.group_id) && val.item === "99"
    );

    if (item_99.length > 0 && item_99[0].hasOwnProperty("pdf_obj")) {
      setPdf_Obj(item_99[0].pdf_obj);
      console.log("FOUND PDF OBJ", item_99[0].pdf_obj);
    } else {
      console.log("NO PDF OBJ DATA");
    }
  }, [pdfGroups, chatStream, pdfData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PDFViewer width="100%" height="650">
          <Document title="Financial Report">
            <Page size="A4" orientation={orientation} style={styles.section}>
              <View style={styles.section}>
                <Text>{desc}</Text>
              </View>
              <View style={styles.section}>
                <PdfMakeHeader pdf_obj={pdf_Obj} />
              </View>
              <View style={styles.section}>
                <PdfMakeTable data={pdfData} pdfHeader={pdfHeader} />
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Grid>
    </Grid>
  );
};

export default PdfReport1;
