import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import useWindowWidth from "../../useWindowWidth";
import { useChatContext } from "src/context/ChatContext";

function isText(value) {
  return typeof value === "string";
}

const Choice_list = ({ val }) => {
  let textValue = isText(val.msg);
  const { windowWidth } = useWindowWidth();
  const { sendUserMessage } = useChatContext();

  return (
    <Box
      sx={{
        mr: 2,
        pl: 1,
        maxWidth: { windowWidth } - 10,
        flexDirection: "row",
        flexWrap: "wrap",
        overflow: "wrap",
      }}
    >
      {textValue && (
        <ReactMarkdown children={val.msg} remarkPlugins={[remarkGfm]} />
      )}
      {val.choices.map((choice, i) => (
        <Button
          sx={{ m: 2 }}
          variant="contained"
          size="small"
          key={choice.label + i}
          onClick={() => {
            sendUserMessage(choice.label, choice);
          }}
          disabled={false}
        >
          {choice.label}
        </Button>
      ))}
    </Box>
  );
};

//disabled={chatStream.length - index !== 1}

export default Choice_list;
