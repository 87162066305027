import getData from "../../AuthContext/get_data";

const handleUserInfo = async (
  ws_id,
  setWorkspaces,
  setReportList,
  setCurrentWorkspace,
  setThemeMode
) => {
  //
  try {
    let taskDetails = {
      task: "getLoginInfo",
      group: "task",
      workSpaceId: ws_id,
    };
    //
    const response = await getData(taskDetails);
    console.log("RESPONSE FROM HANDLE USER INFO: ", response);

    const { data } = response;

    const { workspaces, reportList, userSettings } = data;

    //
    // console.log("WORKSPACES: ", workspaces);
    // console.log("REPORTS LIST: ", reportList);
    // console.log("CURRENT WORKSPACE", userSettings.last_ws_id);
    // console.log("THEME MODE: ", userSettings.darkMode);

    if (workspaces.length === 0) {
      console.log("NO WORKSPACES FOUND");
      setWorkspaces([{ ws_id: 0, ws_desc: "Home" }]); // Default workspace
    }
    if (reportList.length === 0) {
      console.log("NO REPORTS FOUND");
      setReportList(["NO REPORTS"]); // Default report
    }
    if (userSettings.last_ws_id === 0) {
      console.log("NO CURRENT WORKSPACE FOUND");
      setCurrentWorkspace(0); // Default workspace
    }
    if (userSettings.darkMode === "") {
      console.log("NO THEME MODE FOUND");
      setThemeMode("light"); // Default theme
    }

    setWorkspaces(workspaces);
    setReportList(reportList);
    setCurrentWorkspace(userSettings.last_ws_id);
    setThemeMode(userSettings.darkMode);
  } catch (error) {
    console.log("ERROR WITH USER INFO: ", error);
  }
};

export default handleUserInfo;
