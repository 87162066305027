//
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Bottom from "src/components/bottomBar";
import ScrollList from "src/components/chatList";
import Dialogue from "src/components/workspaces";

import Data from "src/components/data";
import ShowPdfDialogue from "src/components/showPdfDialogue";
import SideMenu from "src/components/sideMenu";
import TopBar from "src/components/topBar";
import { useAppContext } from "src/context/AppContext";

function Main(props) {
  const { drawerWidth } = useAppContext();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Dialogue />
      <Data />
      <ShowPdfDialogue />
      <TopBar />
      <SideMenu />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <ScrollList />
        <Bottom />
      </Box>
    </Box>
  );
}

export default Main;
