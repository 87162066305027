import Paper from "@mui/material/Paper";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useAppContext } from "src/context/AppContext";
import { useChatContext } from "src/context/ChatContext";
import { useState } from "react";

export default function Bottom() {
  const { drawerWidth } = useAppContext();
  const { sendUserMessage, busy } = useChatContext();
  const [message, setMessage] = useState("");

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMsg = () => {
    // if message length is less than 10 characters, return
    if (message.length < 2) return;
    sendUserMessage(message);
    setMessage("");
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: 1,
        paddingBottom: 2,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
      elevation={3}
    >
      <OutlinedInput
        spellCheck={true}
        lang={"en"}
        id="filled-adornment-password"
        style={{ width: "100%", textDecoration: false }}
        maxRows={5}
        minRows={1}
        multiline={true}
        onChange={(e) => onMessageChange(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMsg();
            // Prevent default Enter behavior (e.g., creating a new line)
            // Call your sendMsg() function or perform your desired action here
          }
        }}
        value={message}
        color="grey"
        placeholder="Send a message"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              disabled={busy || message.length < 2}
              onClick={() => sendMsg()}
              aria-label="toggle password visibility"
              edge="end"
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </Paper>
  );
}
