import { memo, useEffect, useRef } from "react";

import { useAppContext } from "src/context/AppContext";
import { useChatContext } from "src/context/ChatContext";
import useWindowWidth from "../../useWindowWidth";

import "./markdown_fancy.css";

import Busy from "src/components/busy";
import ChatMarkDownTable from "./chatMarkDownTable";
import ChatMarkDownText from "./chatMarkDownText";
import ChatTextItem from "./chatTextItem";
import ChatMsgEnd from "./chatMsgEnd/chatMsgEnd";

import ChatChoiceList from "./chatChoiceList";
import ChatCheckList from "./chatCheckList";

const MemoizedChatMdText = memo(ChatMarkDownText);
const MemoizedChatMdTable = memo(ChatMarkDownTable);
const MemoizedChatTextItem = memo(ChatTextItem);
const MemoizedChoiceList = memo(ChatChoiceList);
const MemoizedChatMsgEnd = memo(ChatMsgEnd);
const MemoizedCheckList = memo(ChatCheckList);

export default function ScrollList() {
  const { windowWidth } = useWindowWidth();
  const { chatStream } = useChatContext();
  const { drawerWidth, themeMode } = useAppContext();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    //  console.log("CHATSTREAM:", chatStream);
    handleNewChat();
  }, [chatStream]); // Scroll to bottom on initial render

  const handleNewChat = () => {
    // console.log("New chat");
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className="chat-container"
      style={
        windowWidth < 800
          ? { width: windowWidth - 10, marginRight: "5%" }
          : {
              width: windowWidth - drawerWidth - 10,
              marginRight: "5%",
            }
      }
    >
      {chatStream.map((val, index) => (
        <div key={"items-" + index}>
          {val.msgType === "user" && (
            <div
              className={themeMode === "light" ? "user-body" : "user-body_dark"}
              style={{ marginBottom: "0px" }}
            >
              <MemoizedChatTextItem val={val} index={index} />
            </div>
          )}
          {val.msgType === "system" && (
            <div
              className={
                themeMode === "light" ? "markdown-body" : "markdown-body_dark"
              }
              style={{ marginBottom: "0px" }}
            >
              {val?.options?.format === "text" && (
                <MemoizedChatTextItem val={val} />
              )}
              {val?.options?.format === "md" && (
                <MemoizedChatMdText val={val} />
              )}
              {val?.options?.format === "mdt" && (
                <MemoizedChatMdTable val={val} />
              )}
              {val?.options?.format === "choices" && (
                <MemoizedChoiceList val={val} />
              )}
              {val?.options?.format === "checkboxes" && (
                <MemoizedCheckList val={val} />
              )}
              {val?.json_out && <MemoizedChatMsgEnd val={val} />}
            </div>
          )}
        </div>
      ))}
      <Busy />

      <div style={{ height: "100px" }}></div>
      <div ref={messagesEndRef} />
    </div>
  );
}
