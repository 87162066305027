import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import SimpleSelectTable from "./simpleSelectTable/simpleSelectTable";
import { useDataContext } from "src/context/DataContext";
import { useAppContext } from "src/context/AppContext";
import { useChatContext } from "src/context/ChatContext";
import PeriodSelect from "./periodSelect";
import ScenarioSelect from "./scenarioSelect";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Data() {
  const { openData, setOpenData } = useAppContext();
  const {
    getLedgerParents,
    columns,
    rows,
    jv_id_periods,
    setJv_id_periods,
    scenario,
    getScenarios,
    updateCube,
    period,
  } = useDataContext();

  const { optionChoices } = useChatContext();

  useEffect(() => {
    console.log("DATA USE EFFECT");
    //getScenarios();
    getLedgerParents({});
  }, [openData, scenario, period]);

  const handleClose = () => {
    setOpenData(false);
  };

  const dataCallback = (items) => {
    setJv_id_periods(items);
    console.log("dataCallback value", items);
  };

  const handleDoubleClick = (col, row) => {
    console.log("GET LOAD row DETAILS --", row);
    console.log("GET LOAD col DETAILS --", col);
    // view data load details
    if (col?.title === "View") {
      let item = {};
      item["jv_id"] = row["jv_id"];
      item["jv_id_period"] = row["jv_id_period"];
      item["action"] = "load_details_get";
      console.log("GET LOAD DETAILS VIEW --", item);
      optionChoices(item);
      setOpenData(false);
    }
    if (col?.title === "Status") {
      let item = {};
      item["jv_id_period"] = row["jv_id_period"];
      item["jv_status"] = row["jv_status"];
      item["action"] = "load_details_set_archive";
      getLedgerParents(item);
    }
  };

  return (
    <Dialog
      fullScreen
      open={openData}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          {" "}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Data Sources
          </Typography>
          <Button variant="contained">Load Data</Button>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 2 }}>
            <PeriodSelect />
            <ScenarioSelect />
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => updateCube()}
            >
              Update Reporting
            </Button>
          </Box>
          <Box sx={{ p: 2, mb: 2 }}>
            <SimpleSelectTable
              title="All Journals"
              rows={rows}
              columns={columns}
              dataCallback={dataCallback}
              doubleClickCallback={handleDoubleClick}
              selectionModel={jv_id_periods}
            />
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
