import supabase from "./makeSupabase";
//
const getToken = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error("GET TOKEN ERROR 1103.", error);
    return null;
  }
  const { session } = data;
  return session;
};

const handleGetData = async (options) => {
  console.log("GET DATA TASK DETAILS: ", options);

  const session = await getToken();
  if (!session === 1) {
    console.error("GET DATA ERROR 1113.");
    return {
      error: true,
      message: "GET DATA ERROR 1123.",
    };
  }

  try {
    const body = { ...options };
    console.log("GET DATA BODY: ", body);

    const response = await fetch(process.env.REACT_APP_URL_CHAT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.access_token}`,
      },
      body: JSON.stringify(body),
    });

    console.log("GET DATA RESPONSE.:", response); // Check the status

    const data = await response.json();
    console.log("GET DATA SUCCESS:", JSON.stringify(data));
    return data;
    //
  } catch (error) {
    console.log("ERROR GET DATA:", error);
    console.error("HANDLE GET DATA ERROR 1197.", error);
    return {
      error: true,
      message: "ERROR HANDLE GET DATA 7: Failed to fetch data due to an error.",
    };
  } finally {
  }
};

export default handleGetData;
