import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppContext } from "./context/AppContext";
import { ChatProvider } from "./context/ChatContext";
import { DataProvider } from "./context/DataContext";
import Routes from "src/routes";

const App = () => {
  const { themeMode } = useAppContext();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: grey[700],
          },
          secondary: {
            main: grey[400],
          },
        },
      }),
    [themeMode]
  );

  return (
    <ChatProvider>
      <DataProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </DataProvider>
    </ChatProvider>
  );
};

export default App;
