import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAppContext } from "src/context/AppContext";
import useWindowWidth from "src/useWindowWidth";

function TopBar(props) {
  const { drawerWidth, mobileOpen, setMobileOpen, setDrawerWidth } =
    useAppContext();

  const { workspaces, currentWorkspace } = useAppContext();

  const { windowWidth } = useWindowWidth();

  const handleDrawerToggle = () => {
    setDrawerWidth(240);
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar
      position="fixed"
      sxx={{
        width: { xs: windowWidth, md: `calc(100% - ${drawerWidth}px)` },
        ml: { xs: "0px", md: `${drawerWidth}px` },
        mr: { xs: "0px", md: "3px" },
        // backgroundColorx: { xs: "green", sm: "red", md: "blue", lg: "yellow" },
      }}
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={
            drawerWidth === 0 ? { mr: 2 } : { mr: 2, display: { md: "none" } }
          }
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {workspaces.filter((f) => f.ws_id === currentWorkspace)[0].ws_desc}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
